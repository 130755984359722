import axios from "axios";
const BASE_URL = process.env.REACT_APP_NODE_BASE_URL;
const apiNew = axios.create({ baseURL: `${BASE_URL}`, });
apiNew.interceptors.request.use((req) => {
    req.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      // "Authorization": "Bearer " + Data,
    };
    return req;
});
export const newApiWithFormData = axios.create({ baseURL: `${BASE_URL}` });
newApiWithFormData.interceptors.request.use((req) => {
    req.headers = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      // "Authorization": "Bearer " + token,
    };
  return req;
});

export default apiNew;
