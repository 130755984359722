
import { useEffect, useState, useRef } from "react";
import SendMessage from "./ChatBotFiles/sendMessage.mp3"
import receiveMessage from "./ChatBotFiles/receiveMessage.mp3"
import Agent from "./ChatBotFiles/Agent.jpeg"
import chatBotLogoWelcomScreen from "./ChatBotFiles/chatBotLogoWelcomScreen.png"
import closeInColor from "./ChatBotFiles/closeInColor.svg"
import fileIncolor from "./ChatBotFiles/fileIncolor.svg"
import SendInWhite from "./ChatBotFiles/SendInWhite.svg"
import threeDots from "./ChatBotFiles/threeDots.svg"
import threeDotsInColor from "./ChatBotFiles/threeDotsInColor.svg"
import ConvertSpansToButtons from "./ConvertSpansToButtons";
import ConvertSpansToForm from "./ConvertSpansToForm";
import TypingAnimation from "./TypingAnimation";
import ScrollToBottom from 'react-scroll-to-bottom';
import { format } from 'date-fns';
import { motion } from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';
import parse from 'html-react-parser';
import ScaleLoader from "react-spinners/ScaleLoader";
import { Dropdown, Button, Modal } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import MessageInWhite from './ChatBotFiles/messageInWhite.svg'
import { useDispatch, useSelector } from "react-redux";
import { handlerGetOneChatSystemForHome, handlerSendChatData, setMessageListGlobleState } from "./Redux/MessageData";
import CircleLoader from "react-spinners/CircleLoader";
import axios from "axios";
import moment from "moment";
import closeIcons from './ChatBotFiles/closeInColor.svg'
import FeedbackChatBot from "./FeedbackChatBot";
import { ToastContainer } from 'react-toastify';
function App() {
  const dispatch = useDispatch();
  const { MessageListGlobleState, getOneChatSystemForHome } = useSelector((state) => state.MessageData);
  const [MessageList, setMessageList] = useState([])
  const [DisplayFeedback, setDisplayFeedback] = useState(false)
  const [DisplayChatBox, setDisplayChatBox] = useState(false)
  const [ValueInChat, setValueInChat] = useState("")
  const [Data, setData] = useState(false)
  const [ShowModal, setShowModal] = useState(false)
  const [ShowModalForFeedback, setShowModalForFeedback] = useState(false)
  const [triggered, setTriggered] = useState(false);
  const [LoadingMessage, setLoadingMessage] = useState(false)
  const [uniqueId, setUniqueId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [DisplayButton, setDisplayButton] = useState(false)
  const [DisplayHover, setDisplayHover] = useState(false)
  const [DisplayMessageNumber, setDisplayMessageNumber] = useState(true)
  const [DisplayHoverMessage, setDisplayHoverMessage] = useState(false)
  const [DisplayWelcomeScreen, setDisplayWelcomeScreen] = useState(false)
  const [CustomerEmail, setCustomerEmail] = useState(null)
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to array
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]); // Add new files to existing array
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove file by index
  };

  // Event handler to toggle the dropdown's open/closed state
  const handleToggle = () => {
    setIsOpen((prevState) => !prevState);
  };
  let newID = localStorage.getItem("newUniqueID")

  const myFunction = () => {
    let id = uuidv4(); {
      if (!newID)
        localStorage.setItem("newUniqueID", id)
      setDisplayHoverMessage(true)
      setDisplayWelcomeScreen(true)
    }
  };

  useEffect(() => {
    if (newID) {
      setUniqueId(newID)
    }
    return () => { }
  }, [])

  useEffect(() => {
    window.addEventListener('load', myFunction);
    return () => {
      window.removeEventListener('load', myFunction);
    };
  }, []);


  console.log("process.env.REACT_APP_NODE_BASE_URL", process.env.REACT_APP_NODE_BASE_URL)
  const [LoaderFileUpload, setLoaderFileUpload] = useState()
  // console.log("new new new new newq ",Auth)
  async function HandlerUploadFile(inputData) {
    let fileAppendData = ""
    setLoaderFileUpload(true)
    for (let i = 0; i < selectedFiles?.length; i++) {
      var bodyFormData = new FormData();
      bodyFormData.append('file', selectedFiles[i]);
      try {
        const response = await axios.post(process.env.REACT_APP_NODE_BASE_URL + '/file-upload/', bodyFormData);
        console.log('Response:', response.data);
        fileAppendData += response.data?.message;

      } catch (error) {
        console.error('Error:', error);
      }
      try {
        const response = await axios.post(process.env.REACT_APP_NODE_BASE_URL + '/file-upload/', {
          "file_name": selectedFiles[i]?.name,
          "uploaded_by": "Joe",
          "upload_date": moment().format('YYYY-MM-DD')
        });
        console.log('Response:', response.data);
        fileAppendData += response.data?.message;

      } catch (error) {
        console.error('Error:', error);
      }
    }
    setLoaderFileUpload(false)
    setSelectedFiles([])
    handlerSubmitChat(inputData, true)
  }

  console.log("new", selectedFiles)


  function handlerSubmitChat(inputData, filedata) {
    setDisplayFeedback(false)
    if (inputData) {
      const regex = /[a-zA-Z0-9._%+-]+@gmail\.com/;
      const result = inputData.match(regex);
      if (result) {
        setCustomerEmail(result[0])
        setDisplayFeedback(true)
      }
      setValueInChat("")
      const audio = new Audio(SendMessage);
      audio.play();
      if (inputData === "Hi ") {
        setMessageList(prevMessageList => [
          ...prevMessageList,
          { admin: false, message: inputData, time: new Date() },
        ])
      } else {
        if (filedata) {
          setMessageList(prevMessageList => [
            ...prevMessageList,
            { admin: true, message: inputData, time: new Date(), files: selectedFiles },
          ]);
        } else {
          setMessageList(prevMessageList => [
            ...prevMessageList,
            { admin: true, message: inputData, time: new Date() },
          ]);
        }

      }
      setLoadingMessage(true)
      let Id = null;
      if (uniqueId) {
        Id = uniqueId
      } else {
        Id = localStorage.getItem("newUniqueID");
      }
      dispatch(handlerSendChatData({
        "sessionID": Id,
        "senderID": Id + 999999,
        "receiverID": Id,
        "senderType": "user",
        "message": inputData,
        "timestamp": new Date()
      }))
      fetch(process.env.REACT_APP_BOT_BASE_URL + "/insurance-chatbot", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: inputData,
          "secretKey": "HelloWelcomeToTheWorldOfHarryPotter",
          "userID": Id,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setLoadingMessage(false)
          const audio = new Audio(receiveMessage);
          audio.play();
          dispatch(handlerSendChatData({
            "sessionID": Id,
            "senderID": Id + 999999,
            "receiverID": Id,
            "senderType": "bot",
            "message": data?.response,
            "timestamp": new Date()
          }))
          if (data?.response[0]?.coverage_options) {
            setMessageList(prevMessageList => [
              ...prevMessageList,
              { admin: true, message: "list", time: new Date(), data: data?.response[0]?.coverage_options },
            ])
            return
          }

          if (data?.response?.includes("uploaddocs")) {
            setDisplayButton(true)
          }


          if (data?.response?.includes("buttonclass")) {
            setMessageList(prevMessageList => [
              ...prevMessageList,
              { admin: true, message: "button", time: new Date(), data: data?.response, contDown: true },
            ])
              ;
          } else if (data?.response?.includes("showform")) {
            setMessageList(prevMessageList => [
              ...prevMessageList,
              { admin: true, message: "form", time: new Date(), data: data?.response, contDown: true },
            ])
          }
          else {
            setMessageList(prevMessageList => [
              ...prevMessageList,
              { admin: false, message: data?.response, time: new Date(), contDown: true },
            ])
          }
        })
        .catch((error) => {
        });
    }

  }
  useEffect(() => {
    const timer = setTimeout(() => {
      setTriggered(true);
    }, 200);
    if (triggered) {
      if (selectedFiles?.length > 0) {
        HandlerUploadFile(ValueInChat)
      } else {
        console.log("new calingmmnm12121221")
        handlerSubmitChat(ValueInChat)
      }
    }
    return () => clearTimeout(timer);
  }, [triggered]);

  const containerRef = useRef(null);
  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };
  useEffect(() => {
    scrollToBottom();
    dispatch(setMessageListGlobleState(MessageList))
  }, [DisplayChatBox, MessageList]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (selectedFiles?.length > 0) {
        HandlerUploadFile(ValueInChat)
      } else {
        console.log("new calingmmnm12121221")
        handlerSubmitChat(ValueInChat)
      }
    }
  };

  const onSubmit = (data) => {
    const newData = Object.values(data).join(', ');
    setValueInChat(newData)
    setTriggered(!triggered)
  }
  const chatboxVariants = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current?.scrollToBottom();
  },);


  useEffect(() => {
    let newID = localStorage.getItem("newUniqueID")
    dispatch(handlerGetOneChatSystemForHome(newID))
    return () => {
    }
  }, [])

  function handleCloseForFeedback() {
    setShowModalForFeedback(false)
  }

  function handleCloseForFeedback() {
    setShowModalForFeedback(false)
  }
  console.log('first', getOneChatSystemForHome, "===", MessageListGlobleState)
  useEffect(() => {
    if (getOneChatSystemForHome?.data?.length > 0) {
      if (getOneChatSystemForHome?.data?.length > MessageListGlobleState?.length) {
        let tamp = []
        for (let i = 0; i < getOneChatSystemForHome?.data?.length; i++) {
          if (getOneChatSystemForHome?.data[i]?.senderType === "bot" || getOneChatSystemForHome?.data[i]?.senderType === "agent") {
            if (getOneChatSystemForHome?.data[i]?.message?.includes("buttonclass")) {
              tamp.push(
                { admin: false, message: "button", time: new Date(), data: getOneChatSystemForHome?.data[i]?.message, contDown: false }
              )
            } else if (getOneChatSystemForHome?.data[i]?.message?.includes("showform")) {
              tamp.push(
                { admin: false, message: "form", time: new Date(), data: getOneChatSystemForHome?.data[i]?.message, contDown: false }
              )
            } else if (getOneChatSystemForHome?.data[i]?.coverage_options) {
              tamp.push(
                { admin: false, message: "list", time: new Date(), data: getOneChatSystemForHome?.data[i]?.coverage_options, contDown: false }
              )
            } else {
              tamp.push(
                { admin: false, message: getOneChatSystemForHome?.data[i]?.message, time: new Date(), contDown: false }
              )
            }
          }
          else {
            tamp.push(
              {
                admin: true,
                message: getOneChatSystemForHome?.data[i]?.message,
                time: new Date(),
                contDown: false,
              }
            )
          }
        }
        setMessageList(tamp)
      } else {
        setMessageList(MessageListGlobleState)
      }
    }
  }, [getOneChatSystemForHome?.data]);


  const openImageInNewTab = (file) => {
    if (file) {
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
    }
  };

  return (
    <div className="outerContainerChatBot">
      <ToastContainer autoClose={2000} />
      {DisplayChatBox &&
        <motion.div
          className="MessageContainer"
          initial="hidden"
          animate="visible"
          variants={chatboxVariants}
        >
          {DisplayWelcomeScreen && <div className="outerWrapperWelcomeScreen" >
            <div className="d-flex flex-column align-items-center justify-content-center outerWrapperChatBotWelcomeScreen">
              <div className="outerWrapperChatBotWelcomeAgentImage">
                <img src={Agent} alt="chatbot" className="ChatBotWelcomePageUserImage" />
              </div>

              <p className="WelcomeHeading Bold">Welcome to </p>
              <div className="outerWrapperChtBotLogo">
                <img src={chatBotLogoWelcomScreen} alt="" className="chatBotLogoWelcomeScreen" />
              </div>
              <p className="WelcomeHeading mt-3">How can i help you?</p>
              <Button className="ChatBotStartButton mt-3" onClick={() => {
                setDisplayWelcomeScreen(false)
              }}>lets get started</Button>
            </div>
          </div>}
          <div className="outerWrapperHome position-relative" >
            {LoaderFileUpload && <div className="outerWrapperLoaderChatBot d-flex justify-content-center align-items-center">
              <CircleLoader
                loading={true}
                aria-label="Loading Spinner"
                data-testid="loader"
                color="white"
                height={33}
                width={33}
              />
            </div>}
            <div className="chatHeader">
              <div className="d-flex gap-3 align-items-center">
                <div className="outerWrapperChatLogo position-relative">
                  <img src={Agent} alt="" className="ChatLogo" />
                  <div className="OnlineDot"></div>
                </div>
                <div className="d-flex justify-content-between align-items-center w-100">
                  <div>
                    <p className="ChatBoxHeading">Insurance Agent</p>
                    <p className="onlineText">Online</p>
                  </div>
                  {!DisplayHover ? <img src={threeDots} alt="" className="EditIcons" onClick={handleToggle}
                    onMouseEnter={() => {
                      setDisplayHover(true)
                    }}
                  /> :
                    <img src={threeDotsInColor} alt="" className="EditIcons" onClick={handleToggle}
                      onMouseLeave={() => {
                        setDisplayHover(false)
                      }}
                    />}
                  <div className="outerWrapperDropDownInChatBox">
                    <Dropdown show={isOpen} onToggle={handleToggle}>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#/Main Menu" onClick={() => {
                          setValueInChat("Main Menu")
                          setTriggered(!triggered)
                        }} >Main Menu</Dropdown.Item>
                        <Dropdown.Item href="#/action-2" onClick={() => {
                          setDisplayChatBox(false)
                          let id = uuidv4();
                          localStorage.setItem("newUniqueID", id)
                          setMessageList([])
                        }} >Exit Chat</Dropdown.Item>
                        <Dropdown.Item href="https://pbibins.com/"
                          target="_blank"
                          rel="noopener noreferrer">Learn Insurance</Dropdown.Item>
                        <Dropdown.Item href="#/action-3"
                          onClick={() => {
                            setValueInChat("Talk to Agent")
                            setTriggered(!triggered)
                          }}
                        >Talk to Agent</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Call:123-123-1234</Dropdown.Item>
                        <Dropdown.Item href="#/action-4" onClick={() => {
                          setShowModalForFeedback(true)
                        }}>Feedback</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
            <ScrollToBottom className={"outerContainerChatBox"} scrollViewClassName="react-scroll-to-bottom--css-rdflf-1n7m0yu" ref={scrollRef}>
              {MessageList && MessageList?.map((item, index) => {
                if (item?.message === "button") {
                  return (
                    <>
                      <div className=" d-flex gap-2 " key={index}>
                        <div className="outerWrapperBotIcon">
                          <img src={Agent} alt="" className="BotIcon" />
                        </div>
                        <div>
                          <p className="LeftMessageInChat"><ConvertSpansToButtons htmlString={item?.data}
                            setValueInChat={setValueInChat}
                            setTriggered={setTriggered}
                            triggered={triggered}
                          /></p>
                        </div>
                      </div>
                      <div className="d-flex ms-5 justify-content-start mb-3 align-items-center gap-1">
                        <p className="BotTextHolder">Agent . </p>
                        <p className="TimInChat">{moment(item?.time).fromNow()}</p>
                      </div>
                    </>
                  )
                }
                if (item?.message === "list") {
                  return (
                    <>
                      <div className="mb-3 d-flex gap-2 " key={index}>
                        <div className="outerWrapperBotIcon">
                          <img src={Agent} alt="" className="BotIcon" />
                        </div>
                        <div>
                          <p className="LeftMessageInChat">{parse(`${item?.data}`)} </p>
                        </div>
                      </div>
                      <div className="d-flex ms-5 justify-content-start mb-3 align-items-center gap-1">
                        <p className="BotTextHolder">Agent . </p>
                        <p className="TimInChat">{moment(item?.time).fromNow()}</p>
                      </div>
                    </>
                  )
                }
                if (item?.message === "form") {
                  return (
                    <>
                      <div className="d-flex gap-2 " key={index}>
                        <div className="outerWrapperBotIcon">
                          <img src={Agent} alt="" className="BotIcon" />
                        </div>
                        <p className="LeftMessageInChat"><ConvertSpansToForm htmlString={item?.data} onSubmitForm={onSubmit} /></p>
                      </div>
                      <div className="d-flex ms-5 justify-content-start mb-3 align-items-center gap-1">
                        <p className="BotTextHolder">Agent . </p>
                        <p className="TimInChat">{moment(item?.time).fromNow()}</p>
                      </div>
                    </>
                  )
                }
                if (!item?.admin) {
                  return (
                    <>
                      <div className="d-flex gap-2" key={index}>
                        <div className="outerWrapperBotIcon">
                          <img src={Agent} alt="" className="BotIcon" />
                        </div>
                        {!item?.contDown ? (
                          <p className="LeftMessageInChat">{item?.message}</p>
                        ) : (
                          // Render upcoming messages with TypingAnimation
                          <p className="LeftMessageInChat">
                            <TypingAnimation
                              text={item?.message}
                              displayCursor={index === MessageList.length - 1}
                            />
                          </p>
                        )}
                      </div>
                      <div className="d-flex ms-5 justify-content-start mb-3 align-items-center gap-1">
                        <p className="BotTextHolder">Agent . </p>
                        <p className="TimInChat">{moment(item?.time).fromNow()}</p>
                      </div>
                    </>
                  )
                }
                else {
                  return (
                    <div>
                      <div className="d-flex justify-content-end" key={index}>
                        <div>
                          <div className="RightMessageInChat">
                            <div className="outerWrapperFilesInChatBot">
                              {item?.files?.map((item, index) => {
                                return (
                                  <p className="filesInChatBot" onClick={() => openImageInNewTab(item)} key={index}>{item?.name}</p>
                                )
                              })}
                            </div>
                            {item?.message}
                          </div>
                          <div className="d-flex justify-content-end mt-1">
                            <p className="TimInChat">{moment(item?.time).fromNow()}</p>
                          </div>
                        </div>
                      </div>
                      {index === MessageList.length - 1 && <div>
                        {LoadingMessage && <div className="mb-2 d-flex align-items-center gap-2" key={index}>
                          <p className="outerWrapperLoader ">
                            <ScaleLoader
                              loading={true}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                              height={15}
                            />
                          </p>
                          <p className="outerWrapperLoader">
                            Agent is typing...
                          </p>
                        </div>}
                      </div>}
                    </div>
                  )
                }
              })}
            </ScrollToBottom>
            <div className="outerContainerChatFooter">
              <div className="w-100">
                {selectedFiles?.length > 0 && <div className="outerWrapperFilesUploaded">
                  {selectedFiles?.map((item, index) => {
                    return (
                      <div key={index} className="OuterWrapperFiles position-relative">
                        <div className="outerWrapperCloseButtonInChatBot" onClick={() => handleRemoveFile(index)}>
                          <img src={closeInColor} alt="" className="closeButtonInChatBot" />
                        </div>
                        <p className="FileNameInChatBot">{item?.name}</p>
                      </div>
                    )
                  })}
                </div>}
                <textarea autoFocus
                  rows={1}
                  type="text" placeholder="Write a reply..." className="w-100 inputInChat" value={ValueInChat} onChange={(e) => {
                    setValueInChat(e.target.value)
                  }}
                  onKeyPress={handleKeyPress}
                />
              </div>
              {true && <button onClick={handleButtonClick} className="outerWrapperFileButton" style={{ display: 'flex', alignItems: 'center' }}>
                <img src={fileIncolor} alt="Upload Files" />
              </button>}

              {/* Hidden input for file selection, allowing multiple files */}
              <input
                ref={fileInputRef}
                type="file"
                multiple
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />

              <div className="outerContainerSendButtonInChatbot" onClick={() => {
                if (selectedFiles?.length > 0) {
                  HandlerUploadFile(ValueInChat)
                } else {
                  console.log("new calingmmnm12121221")
                  handlerSubmitChat(ValueInChat)
                }
              }}>
                <img src={SendInWhite} alt="" className="SendButtonInChatbot" />
              </div>

            </div>
          </div>
        </motion.div>
      }
      <div className="outerWrapperCloseButton " onClick={() => {
        setDisplayChatBox(!DisplayChatBox)
        if (!DisplayChatBox) {
          setDisplayMessageNumber(false)
          setDisplayHoverMessage(false)
          if (MessageList?.length > 0) {
          } else {
            handlerSubmitChat("Hi ")
          }
        }
        setMessageList(prevMessageList => {
          return prevMessageList.map(message => ({
            ...message,
            contDown: false
          }));
        })
          ;
      }}>
        <motion.div
          initial={{ rotate: 0 }}
          animate={{ rotate: DisplayChatBox ? 180 : 0 }}
          transition={{ duration: 0.5 }}
        >
          {DisplayChatBox ?
            // <img src="/assets/icons/CloseInWhite.svg" alt="" /> 
            <motion.img
              src={closeIcons}
              alt="Close"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            />
            :
            <motion.img
              src={MessageInWhite}
              alt="Open"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            />
          }
        </motion.div>
        {DisplayMessageNumber && <div className="outerWrapperMessageNumber">
          1
        </div>}
        <div className="outerWrapperMessageInMobile">
          {DisplayHoverMessage && <div className="outerWrapperMessageOnHover">

            Hi there 👋
            !  Welcome to Insurance Brokerage Inc, If you need any assistance, I am always here
          </div>}
        </div>

      </div>
      <Modal show={ShowModalForFeedback} onHide={handleCloseForFeedback}>
        <Modal.Header className='justify-content-between'>
          <Modal.Title>
            {Data ? "Edit Feedback" : "Add Feedback"}
          </Modal.Title>
          <img src="/assets/icons/close.svg" alt="close" className='CloseInModal c_pointer' onClick={() => {
            handleCloseForFeedback()
          }} />
        </Modal.Header>
        <Modal.Body><FeedbackChatBot handleClose={handleCloseForFeedback} data={Data} email={CustomerEmail} /></Modal.Body>
      </Modal>
    </div>
  );
}
export default App;
