
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import apiNew from "../utilities/API2";
const initialState = {
	SendMessage: null,
	loadingSpecialNotes: false,
	errorMessage: null,
	getOneChatSystem: [],
	getOneChatSystemForHome: [],
	AllMessagesSystem: [],
	MessageListGlobleState: []
};
///  Get Special Note   //// 
export const handlerSendChatData = createAsyncThunk(
	"message/SendChatData",
	async (data, thunkAPI) => {
		try {
			const response = await apiNew.post(`/messaging-system`, data)
			return response
		} catch (error) {
			return thunkAPI.rejectWithValue(error?.response?.data);
		}
	}
);

///  Get All Message System   ////  /messaging-system
export const handlerAllMessagesSystem = createAsyncThunk(
	"message/getAllMessages",
	async (data, thunkAPI) => {
		try {
			const response = await apiNew.get(`/messaging-system/allchats/${moment().subtract(3, 'months').format('YYYY-MM-DD')}/${moment().add(2, 'days').format('YYYY-MM-DD')}`)
			return response
		} catch (error) {
			return thunkAPI.rejectWithValue(error?.response?.data);
		}
	}
);

///  Get one Chat System   //// 
export const handlerGetOneChatSystem = createAsyncThunk(
	"message/getOneChatSystem",
	async (data, thunkAPI) => {
		try {
			const response = await apiNew.get(`/messaging-system/${data}`)
			return response
		} catch (error) {
			return thunkAPI.rejectWithValue(error?.response?.data);
		}
	}
);

///  Get one Chat System   //// 
export const handlerGetOneChatSystemForHome = createAsyncThunk(
	"message/getOneChatSystemForHome",
	async (data, thunkAPI) => {
		try {
			const response = await apiNew.get(`/messaging-system/${data}`)
			return response
		} catch (error) {
			return thunkAPI.rejectWithValue(error?.response?.data);
		}
	}
);




const userReducer = createSlice({
	name: "person",
	initialState,
	reducers: {
		ResetErrorMessage(state, action) {
			state.errorMessage = null;
		},
		ResetSendMessage(state, action) {
			state.SendMessage = null;
		},
		setMessageListGlobleState(state, action) {
			state.MessageListGlobleState = action.payload.map(item => ({
				...item,
				contDown: false,
			}));
		},
	},
	extraReducers: (builder) => {
		/// get General Data  //// 
		builder.addCase(handlerSendChatData.pending, (state, action) => {
			state.loadingSpecialNotes = true;
			state.SuccessfullyUpdatedData = false;
		});
		builder.addCase(handlerSendChatData.fulfilled, (state, action) => {
			state.SendMessage = action.payload;  // Directly mutate state
			state.loadingSpecialNotes = false;
		});
		builder.addCase(handlerSendChatData.rejected, (state, action) => {
			state.Auth = null;
			state.loadingSpecialNotes = false;
			state.errorMessage = action.payload;
			state.SuccessfullyUpdatedData = false;
		});


		/// get one Chat System  //// 
		builder.addCase(handlerGetOneChatSystem.pending, (state, action) => {
			state.loadingSpecialNotes = true;
			state.SuccessfullyUpdatedData = false;
		});
		builder.addCase(handlerGetOneChatSystem.fulfilled, (state, action) => {
			state.getOneChatSystem = action.payload;  // Directly mutate state
			state.loadingSpecialNotes = false;
		});
		builder.addCase(handlerGetOneChatSystem.rejected, (state, action) => {
			state.Auth = null;
			state.loadingSpecialNotes = false;
			state.errorMessage = action.payload;
			state.SuccessfullyUpdatedData = false;
		});

		/// get one Chat System  //// 
		builder.addCase(handlerGetOneChatSystemForHome.pending, (state, action) => {
			state.loadingSpecialNotes = true;
			state.SuccessfullyUpdatedData = false;
		});
		builder.addCase(handlerGetOneChatSystemForHome.fulfilled, (state, action) => {
			state.getOneChatSystemForHome = action.payload;  // Directly mutate state
			state.loadingSpecialNotes = false;
		});
		builder.addCase(handlerGetOneChatSystemForHome.rejected, (state, action) => {
			state.Auth = null;
			state.loadingSpecialNotes = false;
			state.errorMessage = action.payload;
			state.SuccessfullyUpdatedData = false;
		});
		

		/// all Messages systems //// 
		builder.addCase(handlerAllMessagesSystem.pending, (state, action) => {
			state.loadingSpecialNotes = true;
			state.SuccessfullyUpdatedData = false;
		});
		builder.addCase(handlerAllMessagesSystem.fulfilled, (state, action) => {
			state.AllMessagesSystem = action.payload;  // Directly mutate state
			state.loadingSpecialNotes = false;
		});
		builder.addCase(handlerAllMessagesSystem.rejected, (state, action) => {
			state.Auth = null;
			state.loadingSpecialNotes = false;
			state.errorMessage = action.payload;
			state.SuccessfullyUpdatedData = false;
		});



	},
});

export const { ResetErrorMessage, ResetSendMessage, setMessageListGlobleState } = userReducer.actions;
export default userReducer.reducer;