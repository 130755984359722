import { configureStore } from '@reduxjs/toolkit';
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

//==============Reducers ================
import MessageData from './Redux/MessageData'
import UploadFileInChatBot from './Redux/UploadFileInChatBot'

const reducers = combineReducers({
  MessageData,
  UploadFileInChatBot,
});

const persistConfig = {
  key: "root",
  storage,// Specify the reducers to persist
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
});
