

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { newApiWithFormData } from "../utilities/API2";
const initialState = {    
	uploadFileToChatBot: null,    
	loading: false,    
	errorMessage: null,    
    AddTicketList:null,
    SuccessFullyUploadFile:null
};   
///  get ticket    ////  
export const HandlerUploadFileToAPI = createAsyncThunk(
	"UploadFile/upload",
	async (data, thunkAPI) => {
		try {
			const response = await newApiWithFormData.post(`/file-upload/`,data)
			return response.data
		} catch (error) {
			return thunkAPI.rejectWithValue(error?.response?.data);
		}
	}
);


const userReducer = createSlice({
	name: "person",
	initialState,
	reducers: {
		ResetErrorMessage(state, action) {
			state.errorMessage = null;
		},
        updateSuccessFullyUploadFile(state, action) {
			state.SuccessFullyUploadFile = null;
		},
	},
	extraReducers: (builder) => {
		/// get Staff  //// 
		builder.addCase(HandlerUploadFileToAPI.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(HandlerUploadFileToAPI.fulfilled, (state, action) => {
			state.uploadFileToChatBot = action.payload;
			state.SuccessFullyUploadFile = true
			state.loading = false;
		});
		builder.addCase(HandlerUploadFileToAPI.rejected, (state, action) => {
			state.Auth = null;
			state.loading = false;
			state.errorMessage = action.payload;
		});
	},
});

export const { ResetErrorMessage , updateSuccessFullyUploadFile } = userReducer.actions;
export default userReducer.reducer;