import React, { useState, useEffect } from "react";

const TypingAnimation = ({ text, displayCursor }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [typingCompleted, setTypingCompleted] = useState(false);

  useEffect(() => {
    let typingInterval;
    if (!typingCompleted) {
      typingInterval = setInterval(() => {
        if (currentIndex < text.length) {
          setDisplayedText(prevText => prevText + text[currentIndex]);
          setCurrentIndex(prevIndex => prevIndex + 1);
        } else {
          clearInterval(typingInterval);
          setTypingCompleted(true);
        }
      }, 10); // Adjust typing speed here
    }

    return () => clearInterval(typingInterval);
  }, [text, typingCompleted, currentIndex]);

  useEffect(() => {
    if (!typingCompleted) {
      setCurrentIndex(0); // Reset currentIndex when component mounts
    }
  }, []);

  return (
    <>
      <span>{displayedText}</span>
      {displayCursor && !typingCompleted && <span className="cursor-animation" />}
    </>
  );
};

export default TypingAnimation;
