import React, { useState, useEffect } from 'react';

import { Container, Alert , Button} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Rating } from 'react-simple-star-rating'
import { toast } from 'react-toastify';
const FeedbackChatBot = ({ data, handleClose, email }) => {


    const [ValidationEmail, setValidationEmail] = useState(false)
    const [Values, setValues] = useState({
        customer_id: data?.customer_id ? data?.customer_id : "",
        customer_email: email,
        rating: data?.rating ? data?.rating : "",
        feedback_message: data?.feedback_message ? data?.feedback_message : "",
        agent_id: data?.agent_id ? data?.agent_id : "",
        feedback_date: new Date(),
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValidationEmail(false)
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };
    const [localErrorMessage, setLocalErrorMessage] = useState({
        feedback_message: false,
        rating: false
    });
    const onSubmit = async (e) => {
        e.preventDefault();
        let flag = true;
        if (!Values.feedback_message) {
            setLocalErrorMessage((prevState) => ({ ...prevState, product: true }));
            flag = false;
        }
        if (!Values.rating) {
            setLocalErrorMessage((prevState) => ({ ...prevState, service: true }));
            flag = false;
        }
        if (flag) {
            if (data) {
                Values.id = data.id
                // dispatch(handlerUpdateAnyData({
                //     data: Values,
                //     id: data.id,
                //     url: "feedback",
                //     change: true
                // }))
                try {
                    const response = await fetch(process.env.REACT_APP_NODE_BASE_URL + `/feedback/${data.id}`, {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(Values)
                    });
                    if (response.ok) {
                        console.log('Data sent successfully');
                    } else {
                        // Handle error
                        console.error('Failed to send data');
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            } else {
                // dispatch(handlerAddAnyData({
                //     url: "feedback",
                //     data: Values,
                //     change: true
                // }))
                try {
                    const response = await fetch(process.env.REACT_APP_NODE_BASE_URL + `/feedback`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(Values)
                    });
                    if (response.ok) {
                        console.log('Data sent successfully');
                        toast.success("Successfully Added Feedback")
                        handleClose()
                    } else {
                        // Handle error
                        console.error('Failed to send data');
                        handleClose()
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            }
        }
    };
   


    const [rating, setRating] = useState(0)

    // Catch Rating value
    const handleRating1 = (rate) => {
        setRating(rate)
        Values.rating = rate
        setValues({ ...Values })
    };

    useEffect(() => {
        if (data) {
            setRating(data?.rating)
        }
    }, [data])


    return (
        <Container fluid className='p-0'>
            <form className="row g-3" onSubmit={onSubmit}>

                <div className='w-100'>
                    <Rating
                        onClick={handleRating1}
                        size={50}
                        transition
                        allowFraction
                        showTooltip
                        initialValue={rating}
                        value={rating}
                        tooltipArray={[
                            "Terrible",
                            "Terrible+",
                            "Bad",
                            "Bad+",
                            "Average",
                            "Average+",
                            "Great",
                            "Great+",
                            "Awesome",
                            "Awesome+"
                        ]}
                        fillColorArray={[
                            "#f17a45",
                            "#f17a45",
                            "#f19745",
                            "#f19745",
                            "#f1a545",
                            "#f1a545",
                            "#f1b345",
                            "#f1b345",
                            "#f1d045",
                            "#f1d045"
                        ]}
                    />
                </div>
                <div className="col-md-12">
                    <label className="form-label">Feedback Message</label>
                    <textarea
                        value={Values.feedback_message}
                        onChange={handleChange}
                        className={`form-control ${localErrorMessage.feedback_message ? 'inputFieldRedColor' : ''}`}
                        placeholder="Description"
                        type="text"
                        Name="feedback_message"
                        aria-autocomplete="list"
                        autoCorrect="off"
                        rows={5}
                    />
                    {localErrorMessage.feedback_message && <Alert variant="danger">This field is required</Alert>}
                </div>
                {ValidationEmail && <Alert variant="danger">Enter a valid email address</Alert>}
                {/* {errorMessage && Object.keys(errorMessage).map((key) => (
                    <Alert variant="danger" className='errorMessageBackend'>{key} = {errorMessage[key][0]}</Alert>
                ))} */}
                <div className=" d-flex justify-content-end gap-2">
                    <Button type='button' className='buttonUnActive text-unset' onClick={() => {
                        handleClose()
                    }}>
                        Cancel
                    </Button>
                    {false ? <p>Loading..</p> :
                        <Button type='submit'>
                            Save
                        </Button>
                    }
                </div>
            </form>
        </Container >
    );
};
export default FeedbackChatBot