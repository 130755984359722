
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

const ConvertSpansToForm = ({ htmlString, onSubmitForm }) => {
    const [formFields, setFormFields] = useState([]);
    const [formData, setFormData] = useState({});

    useEffect(() => {
        if (htmlString) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlString, 'text/html');
            const formSpan = doc.querySelector('.showform');
            if (formSpan) {
                const fields = formSpan.textContent.split(', ').map(field => field.trim());
                const initialFormData = {};
                fields.forEach(field => {
                    initialFormData[field] = '';
                });
                setFormData(initialFormData);
                setFormFields(fields);
            }
        }
    }, [htmlString]);

    const handleInputChange = (event) => {

        const { name, value } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmitForm(formData);
        // Clear form fields after submission
        const initialFormData = {};
        formFields.forEach(field => {
            initialFormData[field] = '';
        });
        setFormData(initialFormData);
    };

    return (
        <form className='formWrapper' onSubmit={handleSubmit}>
            {formFields.map((field, index) => (
                <div key={index} className='formField'>
                    <label htmlFor={field}>{field}</label>
                    <input
                        type='text'
                        className='chatInput'
                        id={field}
                        name={field}
                        value={formData[field]}
                        onChange={handleInputChange}
                        required
                    />
                </div>
            ))}
            <Button className='ChatButton mt-2' type='submit'>Submit</Button>
        </form>
    );
};

export default ConvertSpansToForm;
