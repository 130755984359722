import React, { useEffect, useState } from 'react';

const ConvertSpansToButtons = ({ htmlString, setValueInChat, triggered, setTriggered }) => {
    const [convertedHtml, setConvertedHtml] = useState('');
    useEffect(() => {
        if (htmlString) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlString, 'text/html');
            const buttonSpans = doc.querySelectorAll('.buttonclass');
            buttonSpans.forEach(span => {
                const button = document.createElement('buttonclass');
                button.textContent = span.textContent;
                button.className = 'buttonclass';
                span.parentNode.replaceChild(button, span);
            });
            setConvertedHtml(doc.body.innerHTML);
        }
    }, [htmlString]);

    const handleClick = (event) => {
        if (event.target.classList.contains('buttonclass')) {
            setValueInChat(event.target.textContent)
            setTriggered(!triggered)
        }
    }

    return (
        <div className='outerWrapperChatButton' dangerouslySetInnerHTML={{ __html: convertedHtml }} onClick={handleClick} />
    );
};

export default ConvertSpansToButtons;